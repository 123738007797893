.template-modal {
    max-height: 80% !important;
}

.actionArea {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    align-items: stretch;
    justify-content: center;

    .btn {
        flex: 1;
    }
}