.serviceConfiguration__root {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    overflow: auto;

    .serviceConfiguration__card {
        background-color: #15616d;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;

        .serviceConfiguration__card--header {
            text-align: center;
            color: white;
            padding: 1rem;
            flex: 1;

            .serviceConfiguration__card--title {
                display: flex;
                flex-direction: column;
                gap: 0.2rem;

                .serviceConfiguration__card--templates {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                }

                .serviceConfiguration__card--templates>*:not(:last-child)::after {
                    content: '|';
                    margin: 0 0.5rem;
                }

            }

        }

        .serviceConfiguration__card--body {
            position: sticky;
            bottom: 0;
            padding: 1rem;
            background-color: white;
            border-left: 1px solid black;
            border-right: 1px solid black;
            border-bottom: 1px solid black;
            border-radius: 0 0 0.5rem 0.5rem;

            display: flex;
            justify-content: center;
        }
    }

    .serviceConfiguration__card:last-child {
        margin-bottom: 5rem;
    }
}

.customTemplateModal .modal-content {
    max-height: 90dvh !important;
}

.customTemplateModal .modal-content .modal-body {
    overflow: auto !important;
}



@media screen and (max-width: 992px) {
    .serviceConfiguration__root {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .serviceConfiguration__root {
        grid-template-columns: repeat(1, 1fr);
    }
}